@import '../../../styles/var';

.theSupport {
    position: relative;
    z-index: 2;
    .content {
        max-width: 683px;
        @media(max-width: $md3+px) {
            max-width: 100%;
        }
        .text {
            margin: 40px 0 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media(max-width: $md4+px) {
                margin: 20px 0 0;
            }
            @media(max-width: $md6+px) {
                gap: 15px;
            }
        }
    }
    .robot {
        height: auto;
        width: 536px;
        right: 100px;
        bottom: 0;
        position: absolute;
        z-index: 3;
        @media(max-width: $md1+px) {
            width: 380px;
        }
        @media(max-width: $md2+px) {
            right: 50px;
            width: 350px;
        }
        @media(max-width: $md3+px) {
            display: flex;
            position: relative;
            margin: 150px auto 0 auto;
            right: unset;
            bottom: unset;
            width: 450px;
        }
        @media(max-width: $md4+px) {
            width: 380px;
        }
        @media(max-width: $md6+px) {
            width: 232px;
            margin-top: 100px;
        }
    }
    .icons {
        position: absolute;
        z-index: 2;
        width: 757px;
        bottom: 160px;
        right: -30px;
        height: auto;
        @media(max-width: $md1+px) {
            width: 537px;
            bottom: 110px;
            right: 10px;
        }
        @media(max-width: $md2+px) {
            width: 510px;
            right: -40px;
            bottom: 100px;
        }
        @media(max-width: $md3+px) {
            right: 0;
            left: 20px;
            margin: 0 auto;
            bottom: 130px;
            width: 640px;
        }
        @media(max-width: $md4+px) {
            width: 550px;
            bottom: 110px;
            left: 30px;
        }
        @media(max-width: 700px) {
            right: unset;
            left: calc(50% - 530px / 2);
        }
        @media(max-width: $md6+px) {
            width: 328px;
            left: 0;
            right: 0;
            bottom: 80px;
        }
    }
}