@import '../../../styles/var';

.wrap {
    position: relative;
    background-image: url('../../../assets/img/QuaterlyPage/banner_bg.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    padding: 100px 55px 130px;
    margin-top: 80px;

    @media(max-width: $md4+px) {
        padding: 120px 0 0;
        margin-top: 0;
        background-image: none;
    }

    @media(max-width: $md6+px) {
        padding-top: 130px;
    }

    .content {
        position: relative;
        z-index: 5;
        color: #587291;

        .title {

        }

        .text {
            margin-top: 30px;
            max-width: 500px;

            @media (max-width: $md1+px) {
                max-width: 430px;
            }

            @media (max-width: $md6+px) {
                margin-top: 20px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        width: 858px;

        @media (max-width: $md1+px) {
            width: 720px;
        }

        @media (max-width: $md2+px) {
            width: 650px;
        }

        @media(max-width: $md3+px) {
            width: 550px;
        }

        @media(max-width: $md4+px) {
            position: relative;
            right: unset;
            bottom: unset;
            display: flex;
            margin: 0 -40px 0 auto;
            width: 100%;
            max-width: 550px;
        }

        @media(max-width: 600px) {
            margin: 10px -20px 0 auto;
        }
    }
}