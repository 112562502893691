@import "../../../styles/_var.scss";
.most {
    position: relative;
    z-index: 40;
    margin-bottom: 180px;
    @media (max-width: $md4+px) {
        margin-bottom: 150px;
    }
}
.mostBody {
    padding: 170px 0 0 0;
}
.mostTitle {
    max-width: 1146px;
    margin: 0 auto 80px auto;
    @media (max-width: $md4+px) {
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md5+px) {
        margin: 0 auto 20px auto;
    }
    @media (max-width: $md6+px) {
        text-align: left;
    }
}
.mostText {
    max-width: 616px;

    margin-bottom: 80px;

    @media (max-width: $md2+px) {
        margin-bottom: 60px;
    }
    @media (max-width: $md4+px) {
        margin: 0 0 50px 0;
    }
    @media (max-width: $md6+px) {
        margin: 0 0 40px 0;
    }
}
.mostSpoiler {
    max-width: 1146px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Chrome/Safari/Opera */
    -khtml-user-select: none; /* Konqueror */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  not supported by any browser */
    &:after {
        content: "";
        display: "inline-block";
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1346px;
        height: 1px;
        display: block;
        background: #d4dae3;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);
        @media (max-width: $md4+px) {
            width: 1346px;
        }
    }
}
.mostSpoilerTitle {
    padding: 30px 0;

    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: 600 !important;

    color: #587291 !important;

    padding-right: 200px;

    @media (max-width: $md5+px) {
        padding-right: 100px;
    }
    @media (max-width: $md6+px) {
        padding-right: 50px;
    }

    @media (max-width: $md2+px) {
    }
    @media (max-width: $md6+px) {
    }
    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 20px;
        height: 3px;
        background-color: #587291;
        @media (max-width: $md6+px) {
            width: 10px;
            height: 2px;
            // top: 1px;
        }
    }
    &:before {
        content: "";
        display: "inline-block";
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 3px;
        height: 20px;
        background-color: #587291;
        @media (max-width: $md6+px) {
            width: 2px;
            height: 10px;
            right: 4px;
        }
    }
    &.active {
        &:before {
            transform: rotate(90deg);
        }
    }
}
.mostSpoilerContent {
    font-weight: 300 !important;

    color: rgba(0, 0, 0, 0.7);

    max-width: 800px;

    position: relative;
    margin-bottom: 10px;
    display: none;

    ol {
        position: relative;
        left: 22px;
    }
    ul li {
        font-weight: 300;
        position: relative;
        color: rgba(0, 0, 0, 0.7);
        padding-left: 20px;
        &:after {
            content: "";
            display: inline-block;
            left: 5px;
            top: 12px;
            position: absolute;
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.7);
            @media (max-width: $md4+px) {
                top: 10px;
            }
            @media (max-width: $md6+px) {
                top: 7px;
            }
        }
    }
    &:hover {
        &:after {
            transform: scale(1.5);
        }
    }
    &.active {
        display: block;
        &:last-child {
            margin-bottom: 60px;
        }
    }

    &.star {
        &:after {
            display: block;
        }
    }
}
.mostBtn {
    max-width: 1146px;
    margin: 30px auto 0 auto;
}
