@import '../../../styles/var';

.banner {
    position: relative;
    padding-top: 176px;
    @media(max-width: $md1+px) {
        padding-top: 155px;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 130px;
    }
    .light {
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        width: 1040px;
        z-index: 1;
        @media(max-width: $md1+px) {
            width: 900px;
        }
        @media(max-width: $md2+px) {
            width: 800px;
        }
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .light_mob {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        display: none;
        height: auto;
        @media(max-width: $md3+px) {
            display: flex;
            width: 718px;
        }
        @media(max-width: $md6+px) {
            width: 420px;
            top: 300px;
        }
    }
}

.welcomeBonus {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 683px 1fr;
    gap: 60px;
    align-items: center;
    @media(max-width: $md1+px) {
        grid-template-columns: 560px 1fr;
    }
    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column;
        gap: 40px;
        align-items: unset;
    }
    &_02 {
        margin-top: 80px;
        grid-template-columns: 1fr 683px;
        @media(max-width: $md1+px) {
            grid-template-columns: 1fr 560px;
        }
        @media(max-width: $md3+px) {
            flex-direction: column-reverse!important;
        }
        .text {
            margin-top: 0!important;
        }
        img {
            margin-left: 0!important;
            width: 697px!important;
            @media(max-width: $md1+px) {
                width: 500px!important;
            }
            @media(max-width: $md2+px) {
                width: 450px!important;
            }
            @media(max-width: $md3+px) {
                width: 550px!important;
                margin-left: 0;
            }
            @media(max-width: $md4+px) {
                width: 500px!important;
            }
            @media(max-width: $md5+px) {
                width: 420px!important;
            }
            @media(max-width: $md6+px) {
                width: 335px!important;
            }
        }
    }
    .image {
        @media(max-width: $md3+px) {
            display: flex;
            justify-content: center;
        }
        img {
            display: flex;
            margin-left: auto;
            height: auto;
            width: 790px;
            @media(max-width: $md1+px) {
                width: 550px;
            }
            @media(max-width: $md2+px) {
                width: 500px;
            }
            @media(max-width: $md3+px) {
                width: 550px;
                margin-left: 0;
            }
            @media(max-width: $md4+px) {
                width: 500px;
            }
            @media(max-width: $md5+px) {
                width: 420px;
            }
            @media(max-width: $md6+px) {
                width: 335px;
            }
        }
    }
    .content {
        .title {
            color: #587291;
            font-weight: 600;
        }
        .text {
            margin-top: 40px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media(max-width: $md6+px) {
                margin-top: 20px;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 17px;
            font-weight: 400;
            @media(max-width: $md6+px) {
                gap: 9px;
            }
            li {
                font-weight: 400;
                color: #587291;
                padding-left: calc(18px + 15px);
                position: relative;
                b {
                    font-weight: 600;
                }
                @media(max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../assets/img/HomePage/QuickInfo/check.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media(max-width: $md1+px) {
                        top: 3px;
                    }
                    @media(max-width: $md4+px) {
                        top: 1px;
                    }
                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                        top: 3px;
                    }
                }
            }
        }
        .btn {
            margin-top: 40px;
        }
    }
}