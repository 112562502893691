@import "../../../styles/var";
.five {
}
.fiveBody {
}
.fiveWrapper {
    padding: 100px 140px 100px 140px;
    position: relative;
    background: #fbfcfd;
    border-radius: 6px;
    position: relative;
    overflow: hidden;
    @media (max-width: $md1+px) {
        padding: 100px 80px 100px 80px;
    }
    @media (max-width: $md3+px) {
        padding: 60px 30px 60px 30px;
    }
    @media (max-width: $md6+px) {
        padding: 30px 30px 30px 30px;
    }
    &:after {
        content: "";
        display: inline-block;
        right: 0;
        top: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;
        height: 100%;
        background: url("../../../assets/img/trading/5/2.webp") right top no-repeat;
        @media (max-width: $md3+px) {
        }
    }
}
.fiveTitle {
    margin-bottom: 40px;
    max-width: 300px;
    position: relative;
    z-index: 2;
    @media (max-width: $md4+px) {
        margin-bottom: 20px;
    }
}
.fiveSubtitle {
    max-width: 683px;
    position: relative;
    z-index: 2;
    @media (max-width: $md1+px) {
        max-width: 530px;
    }
}


.fiveImg {
    width: 682px;
    position: absolute;
    right: 66px;
    top: 50%;
    transform: translateY(-50%);
    margin: auto;
    z-index: 2;
    @media (max-width: $md1+px) {
        right: 36px;
        max-width: 602px;
        width: 100%;
    }
    @media(max-width: $md2+px) {
        max-width: 500px;
    }   
    @media (max-width: $md3+px) {
        position: relative;
        right: 0;
        margin-top: 40px;
        transform: none;
    }

    img {
        width: 100%;
    }
}
