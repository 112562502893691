@import '../../../styles/var';

.wrap {
    position: relative;

    .content {
        position: relative;
        z-index: 5;

        .title {}

        .text_01 {
            max-width: 800px;
            margin: 20px 0 20px;

            @media (max-width: $md1+px) {
                max-width: 663px;
            }

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }

        .text_02 {
            max-width: 700px;
            margin-top: 20px;

            @media (max-width: $md1+px) {
                max-width: 540px;
            }

            @media (max-width: $md4+px) {
                max-width: 100%;
            }
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .li {
                display: grid;
                grid-template-columns: 18px 1fr;
                gap: 15px;

                @media (max-width: $md6+px) {
                    grid-template-columns: 16px 1fr;
                }

                .mark {
                    width: 100%;
                    padding-top: 4px;

                    @media (max-width: $md1+px) {
                        padding-top: 1px;
                    }

                    @media (max-width: $md4+px) {
                        padding-top: 0;
                    }
                }

                .content {

                    .subtitle {
                        color: #587291;
                        font-weight: 700;
                        line-height: 140%;
                    }

                    .text {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 616px;

        @media (max-width: $md1+px) {
            width: 550px;
        }

        @media (max-width: $md2+px) {
            width: 500px;
        }

        @media (max-width: $md3+px) {
            width: calc(100% - 640px);
        }

        @media (max-width: $md4+px) {
            position: relative;
            top: unset;
            right: unset;
            transform: unset;
            display: flex;
            width: 100%;
            max-width: 450px;
            margin: 50px auto 0;
        }
    }
}