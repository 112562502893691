@import '../../../styles/var';

.chooseYour {
    position: relative;
    .title {
        text-align: center;
        margin-bottom: 85px;
        @media(max-width: $md1+px) {
            margin-bottom: 60px;
        }
    }
    .container {
        overflow: hidden;
        @media(max-width: $md3+px) {
            overflow: visible;
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 20px;
            @media(max-width: $md3+px) {
                display: none;
            }
        }
        .card {
            position: relative;
            z-index: 4;
            // height: 624px;
            width: 100%;
            transition: all .3s;
            padding-top: 25px;
            @media(max-width: $md1+px) {
                padding-top: 22px;
            }
            @media(max-width: $md3+px) {
                height: 100%!important;
            }
            .headerText {
                position: absolute;
                z-index: 8;
                top: 0px;
                left: 60px;
                background: #F2F4F8;
                border-radius: 30px;
                display: inline-flex;
                font-family: $manrope;
                font-weight: 400;
                font-size: 24px;
                line-height: 140%;
                color: #587291;
                padding: 8px 16px;
                @media(max-width: $md1+px) {
                    font-size: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 16px;
                }
            }
            &Content {
                transition: all .3s;
                border-radius: 10px;
                background: #D7DFE9;
                border: 1px solid #F8F8F8;
                position: relative;
                z-index: 6;
                overflow: hidden;
                width: 100%;
                height: 100%;
                padding: 69px 0 32px 60px;
                @media(max-width: $md1+px) {
                    padding: 60px 0 30px 40px;
                }
                @media(max-width: 650px) {
                    padding-right: 40px;
                }
                @media(max-width: 600px) {
                    padding: 46px 16px 26px 20px;
                }
                .cardContentText {
                    max-width: 484px;
                    @media(max-width: $md3+px) {
                        height: 100%;
                    }
                    .cardTitle {
                        font-family: $manrope;
                        font-weight: 700;
                        font-size: 32px;
                        line-height: 140%;
                        color: #587291;
                        @media(max-width: $md1+px) {
                            font-size: 24px;
                        }
                        @media(max-width: $md6+px) {
                            font-size: 20px;
                        }
                    }
                    .cardText {
                        margin: 27px 0 36px 0;
                        @media(max-width: $md1+px) {
                            margin: 20px 0;
                        }
                        @media(max-width: $md6+px) {
                            margin: 10px 0 20px 0;
                        }
                    }
                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        font-weight: 400;
                        @media(max-width: $md6+px) {
                            gap: 8px;
                        }
                        li {
                            padding-left: calc(18px + 15px);
                            position: relative;
                            @media(max-width: $md6+px) {
                                padding-left: 24px;
                            }
                            &::before {
                                content: '';
                                position: absolute;
                                left: 0;
                                top: 6px;
                                width: 18px;
                                height: 18px;
                                background-image: url('../../../assets/img/HomePage/QuickInfo/check.svg');
                                background-position: center center;
                                background-repeat: no-repeat;
                                background-size: cover;
                                @media(max-width: $md1+px) {
                                    top: 3px;
                                }
                                @media(max-width: $md4+px) {
                                    top: 1px;
                                }
                                @media(max-width: $md6+px) {
                                    width: 12px;
                                    height: 12px;
                                    top: 3px;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-top: 40px;
                        @media(max-width: $md1+px) {
                            margin-top: 30px;
                        }
                        a {
                            position: relative;
                            cursor: pointer;
                            font-family: $roboto;
                            font-weight: 400;
                            font-size: 20px;
                            line-height: 115%;
                            padding: 13px 30px;
                            display: inline-flex;
                            color: #587291;
                            background: transparent;
                            border-radius: 30px;
                            border: 1px solid #587291;
                            transition: all .3s;
                            @media(max-width: $md1+px) {
                                font-size: 16px;
                                line-height: 19px;
                            }
                            @media(max-width: $md4+px) {
                                font-size: 14px;
                                line-height: 16px;
                            }
                            &:hover {
                                color: #FFFFFF;
                                background: #587291;
                            }
                        }
                    }
                }
            }
            &Image {
                mix-blend-mode: darken;
                transition: all .3s;
                position: absolute;
                height: auto;
                right: -210px;
                @media(max-width: $md1+px) {
                    right: -160px;
                }
                @media(max-width: $md2+px) {
                    right: -140px;
                }
                @media(max-width: $md3+px) {
                    right: 0;
                }
                @media(max-width: $md5+px) {
                    right: -100px;
                }
                @media(max-width: 650px) {
                    right: -100px!important;
                }
                @media(max-width: $md6+px) {
                    right: -90px!important;
                }
            }
            &_01 {
                .cardImage {
                    bottom: 0;
                    width: 387px;
                    @media(max-width: $md1+px) {
                        width: 320px;
                    }
                    @media(max-width: $md2+px) {
                        width: 280px;
                    }
                    @media(max-width: $md3+px) {
                        width: 350px;
                    }
                    @media(max-width: $md4+px) {
                        width: 300px;
                    }
                    @media(max-width: 850px) {
                        width: 250px;
                    }
                    @media(max-width: 650px) {
                        bottom: -25px;
                        width: 200px;
                    }
                    @media(max-width: $md6+px) {
                        width: 180px;
                    }
                }
            }
            &_02 {
                &::before {
                    content: '';
                    position: absolute;
                    z-index: 1;
                    top: 0;
                    left: -20px;
                    height: 100%;
                    width: 34px;
                    background: #F2F4F8;
                    @media(max-width: $md3+px) {
                        content: none;
                    }
                }
                .cardImage {
                    bottom: 100px;
                    width: 448px;
                    @media(max-width: $md1+px) {
                        width: 320px;
                        bottom: 60px;
                    }
                    @media(max-width: $md2+px) {
                        width: 280px;
                    }
                    @media(max-width: $md3+px) {
                        width: 350px;
                        right: 20px;
                    }
                    @media(max-width: $md4+px) {
                        width: 300px;
                        bottom: 40px;
                    }
                    @media(max-width: 850px) {
                        width: 250px;
                    }
                    @media(max-width: $md5+px) {
                        right: 5px;
                    }
                    @media(max-width: 650px) {
                        width: 200px;
                        bottom: 10px;
                    }
                    @media(max-width: $md6+px) {
                        width: 180px;
                    }
                }
            }
            &.card_01_hover {
                width: calc(100% + 180px)!important;
                z-index: 10;
                img {
                    right: 0;
                }
                .cardContent {
                    background: #fbfcfd;
                }
            }
            &.card_01_hover_02 {
                transform: translateX(180px);
            }
            &.card_02_hover {
                width: calc(100% + 180px)!important;
                transform: translateX(-180px);
                z-index: 10;
                .cardImage {
                    right: 12px;
                }
                .cardContent {
                    background: #fbfcfd;
                }
            }
        }
        .swiperWr {
            display: none;
            @media(max-width: $md3+px) {
                display: block;
            }
            :global(.chooseYourSwiper) {
                height: 100%!important;
                overflow: visible;
                padding-bottom: 40px;
                @media(max-width: $md6+px) {
                    padding-bottom: 28px;
                }
            }
            :global(.chooseYourSwiperSlide) {
                height: auto!important;
            }
            :global(.swiper-pagination) {
                bottom: 0;
            }
            :global(.swiper-pagination-bullet) {
                opacity: 1;
                background: #D7DFE9;
            }
            :global(.swiper-pagination-bullet-active) {
                background: #587291;
            }
        }
    }
}