@import '../../../styles/var';

.tableBlock {
    position: relative;
    z-index: 5;
    .light {
        position: absolute;
        top: -128px;
        left: 0;
        width: 100%;
        height: auto;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .container {
        position: relative;
        @media(max-width: $md4+px) {
            width: 100%!important;
            max-width: 100%!important;
            padding: 0!important
        }
        .tableWr {
            position: relative;
            background: rgba(255, 255, 255, 0.7);
            border-radius: 6px;
            padding: 70px 86px;
            @media(max-width: $md1+px) {
                padding: 50px 80px;
            }
            @media(max-width: $md3+px) {
                padding: 0;
                border-radius: 0;
                background: none;
            }
            @media(max-width: $md4+px) {
                padding: 0 40px;
                overflow: scroll;
                overflow-y: hidden;
                padding-bottom: 20px;
            }
            @media(max-width: 600px) {
                padding: 0 20px 20px 20px;
            }
            table {
                vertical-align: center;
                width: 100%;
                border-spacing: 0;
                @media(max-width: $md4+px) {
                    width: 950px;
                }
                @media(max-width: $md6+px) {
                    width: 750px;
                }
                .headerRow {
                    td {
                        padding-bottom: 45px;
                        &:nth-child(1) {
                            font-weight: 500;
                        }
                        .td {
                            display: flex;
                            flex-direction: column;
                            gap: 10px;
                            @media(max-width: $md4+px) {
                                gap: 8px;
                            }
                            @media(max-width: $md6+px) {
                                gap: 4px;
                            }
                            .tableTitle {
                                font-family: $manrope;
                                font-weight: 700;
                                font-size: 24px;
                                line-height: 140%;
                                color: #587291;
                                @media(max-width: $md1+px) {
                                    font-size: 20px;
                                }
                                @media(max-width: $md6+px) {
                                    font-size: 16px;
                                }
                            }
                            .tableDesc {
                                color: #587291;
                                font-weight: 400;
                            }
                        }
                    }
                }
                tr {
                    td {
                        text-align: center;
                        padding: 15px 0;
                        vertical-align: top;
                        @media(max-width: $md1+px) {
                            padding: 12px 0;
                        }
                        @media(max-width: $md4+px) {
                            padding: 10px 0;
                        }
                        &:nth-child(1) {
                            text-align: left;
                        }
                        .imgCheck {
                            height: auto;
                            width: 31px;
                            @media(max-width: $md1+px) {
                                width: 24px;
                            }
                            @media(max-width: $md6+px) {
                                width: 20px;
                            }
                        }
                        .imgMinus {
                            height: auto;
                            width: 20px;
                            vertical-align: baseline;
                            @media(max-width: $md1+px) {
                                width: 18px;
                            }
                            @media(max-width: $md6+px) {
                                width: 14px;
                            }
                        }
                    }
                }
            }
        }
    }
}