@import '../../../styles/var';

.whyChoose {
    position: relative;
    &Body {
        position: relative;
        .content {
            width: 700px;
            @media(max-width: $md1+px) {
                max-width: 640px;
            }
            @media(max-width: $md3+px) {
                width: 100%;
                max-width: 100%;
            }
            .title {
                text-align: center;
                margin-bottom: 60px;
                @media(max-width: $md6+px) {
                    margin-bottom: 40px;
                }
            }
            .cards {
                display: grid;
                grid-template-columns: repeat(2, 300px);
                gap: 60px 100px;
                @media(max-width: $md1+px) {
                    grid-template-columns: repeat(2, 270px);
                    gap: 50px 100px;
                }
                @media(max-width: $md3+px) {
                    grid-template-columns: repeat(3, 1fr);
                    justify-content: center;
                    gap: 50px 80px;
                }
                @media(max-width: $md4+px) {
                    gap: 40px 50px;
                }
                @media(max-width: $md5+px) {
                    grid-template-columns: repeat(2, 1fr);
                    gap: 40px 30px;
                }
                @media(max-width: 600px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 40px;
                }
                .card {
                    text-align: center;
                    margin: 0 auto;
                    &:hover {
                        .cardIcon {
                            transform: scale(1.05);
                        }
                    }
                    @media(max-width: $md5+px) {
                        width: 275px;
                    }
                    @media(max-width: $md6+px) {
                        width: 250px;
                    }
                    &Icon {
                        height: auto;
                        width: 60px;
                        transition: all .3s;
                        @media(max-width: $md6+px) {
                            width: 45px;
                        }
                    }
                    &Text {
                        margin-top: 20px;
                        @media(max-width: $md6+px) {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
        .image {
            width: 892px;
            height: auto;
            position: absolute;
            top: 0;
            right: -150px;
            @media(max-width: $md1+px) {
                right: -160px;
                width: 700px;
            }
            @media(max-width: $md2+px) {
                width: 600px;
                right: -120px;
                top: 80px;
            }
            @media(max-width: $md3+px) {
                position: relative;
                display: flex;
                margin: 50px 0 0 auto;
                top: unset;
                right: -40px;
                width: 750px;
            }
            @media(max-width: $md4+px) {
                width: 650px;
            }
            @media(max-width: $md5+px) {
                width: 600px;
            }
            @media(max-width: 600px) {
                width: 100%;
                right: -20px;
                margin-top: 40px;
            }
        }
    }
}