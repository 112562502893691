@import '../../../styles/var';

.quickInfo {
    position: relative;
    .light {
        position: absolute;
        z-index: 1;
        right: 0;
        width: 100%;
        height: auto;
        bottom: 0;
        @media(max-width: $md6+px) {
            width: 150%;
        }
    }
    &Body {
        position: relative;
        z-index: 3;
        .title {
            max-width: 800px;
        }
        ul {
            margin: 40px 0 60px 0;
            display: flex;
            flex-direction: column;
            gap: 15px;
            max-width: 614px;
            b {
                font-weight: 500;
            }
            @media(max-width: $md1+px) {
                margin: 40px 0;
                gap: 12px;
            }
            @media(max-width: $md6+px) {
                margin: 20px 0 40px 0;
                gap: 10px;
            }
            li {
                padding-left: calc(18px + 15px);
                position: relative;
                @media(max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../assets/img/HomePage/QuickInfo/check.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media(max-width: $md1+px) {
                        top: 3px;
                    }
                    @media(max-width: $md4+px) {
                        top: 1px;
                    }
                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                        top: 3px;
                    }
                }
            }
        }
        .image {
            height: auto;
            width: 616px;
            position: absolute;
            top: 0;
            right: 100px;
            @media(max-width: $md1+px) {
                width: 500px;
                right: 0;
            }
            @media(max-width: $md2+px) {
                width: 450px;
            }
            @media(max-width: $md3+px) {
                display: flex;
                position: relative;
                right: unset;
                top: unset;
                margin: 50px auto 0 auto;
            }
            @media(max-width: $md5+px) {
                width: 400px;
            }
            @media(max-width: $md6+px) {
                width: 320px;
            }
        }
    }
}