@import '../../../styles/var';

.wrap {
    position: relative;

    .title {

    }

    .text {
        margin-top: 30px;
        display: grid;
        grid-template-columns: repeat(2, minmax(300px, 683px));
        justify-content: space-between;
        gap: 50px;

        @media (max-width: $md4+px) {
            display: flex;
            flex-direction: column;
            gap: 20px;
        }

        @media (max-width: $md6+px) {
            margin-top: 20px;
        }
    }
}