@import "../../../styles/var";

.three {
}
.threeBody {
}
.threeTitle {
}
.threeTop {
    display: flex;
    margin: 60px 0;

    @media (max-width: 1050px) {
        flex-wrap: wrap;
        max-width: 900px;
    }
    @media (max-width: $md4+px) {
        max-width: 800px;
        margin: 40px 0 20px 0;
    }
    @media (max-width: $md6+px) {
        margin: 30px 0 10px 0;
    }
}
.threeTopTab {
    position: relative;
    cursor: pointer;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: #b9c9dd;
        opacity: 0.3;
        z-index: -1;
        border-radius: 50px;
        transition: all 0.3s linear;
    }
    @media (max-width: 1050px) {
        margin-right: 20px;
    }
    @media (max-width: $md6+px) {
        margin-right: 5px;
    }
    @media (max-width: $md4+px) {
        margin-bottom: 20px;
    }
    margin-right: 40px;
    &:last-child {
        margin-right: 0;
    }
    padding: 9px 24px;
    font-weight: 500;
    color: #587291;
    @media (max-width: $md6+px) {
        padding: 5px 14px;
    }
    &:hover,
    &.active {
        &:after {
            opacity: 1;
        }
    }
}
.threeContentTab {
}
.threeRow {
    display: flex;
    justify-content: space-between;
    max-width: 1500px;
}
.threeRowLeft {
    flex: 0 1 683px;
    position: relative;
    z-index: 2;
    @media (max-width: $md2+px) {
        flex: 0 1 580px;
    }
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.threeRowRight {
    flex: 0 1 564px;
    @media (max-width: $md3+px) {
        display: none;
    }

    img {
        width: 100%;
    }
}

.threeRowLeftTitle {
    margin-bottom: 30px;
    font-weight: 500;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #587291;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.threeRowLeftText {
    margin-bottom: 20px;
    @media (max-width: $md6+px) {
        margin-bottom: 15px;
    }
}
.threeRowLeftIcons {
    display: flex;
    margin-bottom: 20px;
    max-width: 362px;
    justify-content: space-between;
    @media (max-width: 600px) {
        max-width: 250px;
    }
}
.threeRowLeftIconsItem {
    text-align: center;
}
.threeRowLeftIconsItemImg {
    width: 40px;
    margin: 0 auto 10px auto;
}
.threeRowLeftIconsItemText {
    font-weight: 400;
}
.threeRowLeftBtn {
    padding-top: 20px;
}
.threeRowRight {
    img {
        mix-blend-mode: darken;
    }
}
.threeRowLeftList {
    display: flex;
    margin-bottom: 40px;
}
.threeRowLeftListLeft {
    flex: 0 1 253px;
}
.threeRowLeftListItem {
    margin-bottom: 10px;
    &:last-child {
        margin-bottom: 0;
    }
    padding-left: 30px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 6px;
        position: absolute;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/trading/3/2/2.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            top: 3px;
        }
        @media (max-width: $md4+px) {
            top: 0;
        }
    }
}
.threeRowLeftListRight {
    flex: 0 1 253px;
    margin-left: 5px;
}
.threeRowLeftCurrency {
    margin-bottom: 40px;
}
.threeRowLeftCurrencyItem {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    &:last-child {
        margin-bottom: 0;
    }
    display: flex;
    img {
        height: 20px;
        width: auto;
    }
}
.threeRowLeftCurrencyItemText {
    font-weight: 40;
    margin-left: 20px;
}
