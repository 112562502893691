@import "../../../styles/var";

.first {}

.firstBody {
    position: relative;
    padding: 180px 0 0 0;

    @media (max-width: $md1+px) {
        padding: 155px 0 0 0;
    }

    @media (max-width: $md3+px) {
        padding: 139px 0 0 0;
    }

    @media (max-width: $md5+px) {
        padding: 129px 0 0 0;
    }
}

.light {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    width: 1269px;
    height: auto;

    @media(max-width: $md1+px) {
        width: 1000px;
    }

    @media(max-width: $md2+px) {
        width: 800px;
    }

    @media(max-width: $md4+px) {
        display: none;
    }
}

.light_mob {
    display: none;
    height: auto;

    @media(max-width: $md4+px) {
        display: flex;
        position: absolute;
        bottom: -300px;
        right: 0;
        width: 100%;
    }

    @media(max-width: $md5+px) {
        bottom: -250px;
    }

    @media(max-width: 600px) {
        bottom: -200px;
    }

    @media(max-width: $md6+px) {
        width: 500px;
        bottom: -230px;
    }
}

.firstRow {
    position: relative;
    z-index: 3;
    display: flex;
    justify-content: space-between;

    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}

.firstRowLeft {
    flex: 0 1 683px;
    opacity: 0;
    transform: translate(0, 200px);

    @media (max-width: $md3+px) {
        opacity: 1;
        transform: translate(0, 0px);
        flex: 0 1 100%;
    }
}

.firstTitle {
    max-width: 639px;
    margin-bottom: 40px;
    font-weight: 600;
    color: #587291;

    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}

.firstText {
    margin-bottom: 40px;
    font-weight: 300;
    color: rgba(0, 0, 0, 0.7);
}

.firstBtn {}

.firstRowRight {
    width: 875px;
    opacity: 0;
    transform: scale(0.8) translate(0, 200px);
    
    @media(max-width: $md1+px) {
        width: 650px;
    }

    @media (max-width: $md3+px) {
        opacity: 1;
        transform: scale(1) translate(0, 0px);
        margin: 30px auto 0 auto;
        flex: unset;
        max-width: 700px;
        width: 100%;
    }

    img {
        width: 100%;
    }
}