@import '../../../styles/var';

.speaker {
    position: relative;
    margin-top: 180px;

    padding: 60px 0 190px 0;

    @media(max-width: $md1+px) {
        margin-top: 150px;
    }

    @media(max-width: $md3+px) {
        padding: 0;
        background: transparent;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 10;
        max-width: 700px;

        @media(max-width: $md1+px) {
            max-width: 520px;
        }

        @media(max-width: $md3+px) {
            max-width: 100%;
        }

        .title {}

        .text {
            margin-top: 30px;

            a {
                text-decoration: underline;
                font-weight: 500;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        bottom: 0;
        right: 0;
        width: 641px;

        @media(max-width: $md2+px) {
            width: 600px;
        }

        @media(max-width: $md3+px) {
            bottom: unset;
            right: unset;
            position: relative;
            display: flex;
            margin: 30px auto 0 auto;
            max-width: 600px;
            width: 100%;
        }
    }
}

.award {
    display: flex;
    justify-content: center;
    gap: 200px;
    margin-top: 180px;

    @media(max-width: $md1+px) {
        margin-top: 150px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    @media(max-width: $md4+px) {
        gap: 60px;
        justify-content: space-around;
    }

    @media(max-width: $md5+px) {
        gap: 20px;
    }

    &Image {
        width: 301px;

        @media(max-width: $md1+px) {
            width: 250px;
        }

        @media(max-width: $md5+px) {
            width: 200px;
        }

        @media(max-width: 600px) {
            width: 160px;
        }

        @media(max-width: $md6+px) {
            width: 150px;
        }
    }
}