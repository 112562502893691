@import '../../../styles/var';

.banner {
    position: relative;
    padding-top: 176px;
    @media(max-width: $md1+px) {
        padding-top: 155px;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 130px;
    }
    .light {
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        width: 1269px;
        height: auto;
        @media(max-width: $md1+px) {
            width: 1000px;
        }
        @media(max-width: $md2+px) {
            width: 800px;
        }
        @media(max-width: $md4+px) {
            display: none;
        }
    }
    .light_mob {
        display: none;
        height: auto;
        @media(max-width: $md4+px) {
            display: flex;
            position: absolute;
            bottom: -300px;
            right: 0;
            width: 100%;
        }
        @media(max-width: $md5+px) {
            bottom: -250px;
        }
        @media(max-width: 600px) {
            bottom: -200px;
        }
        @media(max-width: $md6+px) {
            width: 500px;
            bottom: -230px;
        }
    }
    &Body {
        position: relative;
        z-index: 5;
        .title {
            position: relative;
            z-index: 5;
            color: #587291;
            font-weight: 600;
        }
        .textContent {
            position: relative;
            z-index: 5;
            max-width: 683px;
            @media(max-width: $md1+px) {
                max-width: 500px;
            }
            @media(max-width: $md4+px) {
                max-width: 450px;
            }
        }
        .text {
            margin: 40px 0 30px 0;
            @media(max-width: $md6+px) {
                margin: 20px 0;
            }
        }
        .subtext {
            font-weight: 500;
            color: rgba(88, 114, 145, 1);
        }
        .btn {
            position: relative;
            z-index: 5;
            margin-top: 40px;
        }
        .image {
            height: auto;
            width: 758px;
            position: absolute;
            right: 60px;
            top: -20px;
            z-index: 2;
            @media(max-width: $md1+px) {
                width: 600px;
                top: 0;
            }
            @media(max-width: $md2+px) {
                width: 550px;
                right: 50px;
            }
            @media(max-width: $md3+px) {
                width: 400px;
                top: 60px;
                right: 0;
            }
            @media(max-width: $md4+px) {
                display: flex;
                position: relative;
                top: unset;
                right: unset;
                width: 533px;
                margin: 90px auto 0 auto;
            }
            @media(max-width: $md4+px) {
                width: 400px;
            }
            @media(max-width: $md6+px) {
                margin-top: 70px;
                width: 335px;
            }
        }
    }
}