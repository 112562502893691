@import '../../../styles/var';

.videos {
    position: relative;
    margin-top: 200px;
    @media (max-width: $md1+px) {
        margin-top: 150px;
    }
    @media(max-width: $md4+px) {
        margin-top: 130px;
    }
    @media (max-width: $md6+px) {
        margin-top: 130px;
    }
    .title {

    }
    .content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-template-rows: repeat(1, 1fr);
        margin-top: 40px;
        gap: 35px;
        @media(max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }   
        @media(max-width: 700px) {
            gap: 25px;
        }
        @media(max-width: 600px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }
        @media(max-width: $md6+px) {
            margin-top: 30px;
        }
        .videoWrap {
            width: 100%;
            height: 100%;
            .videoTitle {
                font-size: 18px;
                line-height: 150%;
                font-weight: 500;
                margin-bottom: 5px;
                @media(max-width: $md1+px) {
                    font-size: 16px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
            .video {
                width: 100%;
            }
        }
    }
}