@import "./_var.scss";

body,
html {
    background: #F2F4F8;
    color: rgba(0, 0, 0, 0.7);

    &.lock {
        overflow: hidden;
    }
}

.font-64 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 64px;
    line-height: 140%;

    @media (max-width: $md1+px) {
        font-size: 48px;
    }

    @media (max-width: $md4+px) {
        font-size: 40px;
    }

    @media (max-width: $md6+px) {
        font-size: 32px;
    }
}

.font-48 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 48px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: $md1+px) {
        font-size: 40px;
    }

    @media (max-width: $md4+px) {
        font-size: 36px;
    }

    @media (max-width: $md6+px) {
        font-size: 28px;
    }
}

.font-40 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 500;
    font-size: 40px;
    line-height: 150%;

    @media (max-width: $md1+px) {
        font-size: 36px;
    }

    @media (max-width: $md4+px) {
        font-size: 32px;
    }

    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-32 {
    font-family: $manrope;
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 140%;

    @media (max-width: $md1+px) {
        font-size: 28px;
    }

    @media (max-width: $md4+px) {
        font-size: 24px;
    }

    @media (max-width: $md6+px) {
        font-size: 20px;
    }
}

.font-24 {
    font-family: $roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;

    @media (max-width: $md1+px) {
        font-size: 20px;
    }

    @media (max-width: $md6+px) {
        font-size: 16px;
    }
}

.font-20 {
    font-family: $roboto;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 150%;
    color: rgba(0, 0, 0, 0.7);

    @media (max-width: $md1+px) {
        font-size: 16px;
    }

    @media (max-width: $md4+px) {
        font-size: 14px;
    }
}

.mt {
    margin-top: 180px;

    @media (max-width: $md1+px) {
        margin-top: 160px;
    }

    @media (max-width: $md4+px) {
        margin-top: 120px;
    }
}

.mb {
    margin-bottom: 180px;

    @media (max-width: $md1+px) {
        margin-bottom: 160px;
    }

    @media (max-width: $md4+px) {
        margin-bottom: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    background-color: transparent !important;
    border: none;
    color: rgba(0, 0, 0, 0.7) !important;
    text-transform: uppercase !important;
    padding-right: 25px !important;
    font-size: 18px;
    border: none !important;

    &:hover {
        box-shadow: none !important;
    }
}

.Dropdown-menu {
    font-family: $roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase !important;
}

.Dropdown-arrow {
    border-color: #201abe transparent transparent;
}

.is-open .Dropdown-arrow {
    border-color: transparent transparent #201abe;
}