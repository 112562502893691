@import '../../../styles/var';

.wrap {
    position: relative;
    overflow: hidden;
    border-radius: 6px;
    background: #FFF;
    display: flex;
    align-items: center;
    min-height: 513px;
    padding: 40px 130px;

    @media (max-width: $md1+px) {
        min-height: 420px;
        padding: 40px 80px;
    }

    @media (max-width: $md2+px) {
        min-height: 400px;
    }

    @media (max-width: $md3+px) {
        min-height: unset;
        padding: 40px;
    }

    @media (max-width: $md4+px) {
        display: block;
        padding-bottom: 0;
    }

    @media (max-width: $md6+px) {
        padding: 30px 20px 0 20px;
    }

    .content {
        position: relative;
        z-index: 5;

        .title {
            max-width: 580px;

            @media (max-width: $md1+px) {
                max-width: 500px;
            }
        }

        .text {
            margin: 40px 0 50px;
            max-width: 600px;

            @media (max-width: $md1+px) {
                max-width: 450px;
                margin: 30px 0 40px;
            }

            @media (max-width: $md3+px) {
                max-width: 400px;
            }

            @media (max-width: $md6+px) {
                margin: 20px 0 40px;
            }
        }

        .btn {

        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        bottom: 0;
        height: 100%;

        @media (max-width: $md4+px) {
            position: relative;
            display: flex;
            margin: 50px -40px 0 auto;
            height: unset;
            width: 100%;
            max-width: 400px;
        }

        @media (max-width: $md6+px) {
            margin-right: -20px;
        }
    }
}