@import "../../../styles/var";

.button {
    position: relative;
    cursor: pointer;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: $roboto;
    font-weight: 400;
    font-size: 20px;
    line-height: 115%;
    padding: 13px 30px;
    color: #FFFFFF;
    display: inline-flex;
    background: #587291;
    border-radius: 30px;
    border: 1px solid #587291;
    transition: all .3s;
    @media(max-width: $md1+px) {
        font-size: 16px;
        line-height: 19px;
    }
    @media(max-width: $md4+px) {
        font-size: 14px;
        line-height: 16px;
    }
    &:hover {
        color: #587291;
        background: transparent;
    }
}