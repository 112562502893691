@import '../../../styles/var';

.schedule {
    position: relative;
    margin-top: 180px;

    @media(max-width: $md1+px) {
        margin-top: 150px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    .title {
        text-align: center;
    }

    .tableWrap {
        max-width: 550px;
        margin: 75px auto 0 auto;

        @media(max-width: $md1+px) {
            margin-top: 60px;
        }

        @media(max-width: $md4+px) {
            margin-top: 50px;
        }

        @media(max-width: $md6+px) {
            margin-top: 40px;
        }

        table {
            width: 100%;
            border-spacing: 0;
            font-family: $roboto;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: #004B5F;

            tr {
                &:nth-child(1) {
                    td {
                        font-weight: 600;
                    }
                }

                td {
                    height: 50px;
                    border-bottom: 1px solid #AAAAAA;
                    padding-right: 10px;
                }
            }
        }
    }

    .btn {
        text-align: center;
        margin-top: 60px;
    }
}