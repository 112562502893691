@import '../../../styles/var';

.banner {
    position: relative;
    margin-top: 120px;

    @media(max-width: $md4+px) {
        margin-top: 100px;
    }

    @media(max-width: $md6+px) {
        margin-top: 80px;
    }

    .title {
        text-align: center;
    }

    .text {
        text-align: center;
        margin: 40px auto 80px auto;
        max-width: 740px;

        @media(max-width: $md1+px) {
            margin-bottom: 80px;
        }

        @media (max-width: $md4+px) {
            max-width: 500px;
        }

        @media(max-width: $md6+px) {
            margin-bottom: 40px;
            margin-top: 20px;
        }
    }

    .docs {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 50px 50px;
        justify-content: center;
        max-width: 1300px;
        margin: 0 auto;

        @media(max-width: $md2+px) {
            gap: 50px 40px;
        }

        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 50px 60px;
        }

        @media(max-width: $md6+px) {
            grid-template-columns: 1fr;
            gap: 20px;
        }

        .doc {
            display: flex;
            align-items: center;
            gap: 40px;
            transition: all .1s;

            @media(max-width: $md2+px) {
                gap: 30px;
            }

            @media (max-width: $md6+px) {
                gap: 20px;
            }

            &:hover {
                opacity: 0.8;
            }

            &Icon {
                width: 42px;

                @media(max-width: $md4+px) {
                    width: 35px;
                }

                @media (max-width: $md6+px) {
                    width: 30px;
                }
            }

            &Text {
                font-size: 20px;
                color: #587291;
                font-weight: 500;
                line-height: 125%;

                @media(max-width: $md1+px) {
                    font-size: 18px;
                }

                @media (max-width: $md4+px) {
                    font-size: 16px;
                }

                @media(max-width: $md6+px) {
                    font-size: 14px;
                }
            }
        }
    }
}