@import '../../../styles/var';

.ourSecurity {
    position: relative;
    margin-top: 300px;
    @media(max-width: $md1+px) {
        margin-top: 240px;
    }
    @media(max-width: $md3+px) {
        margin-top: 150px;
    }
    @media(max-width: $md4+px) {
        margin-top: 120px;
    }
    @media(max-width: $md6+px) {
        margin-top: 60px;
    }
    .light {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        z-index: 1;
        pointer-events: none;
        @media(max-width: $md4+px) {
            width: 1000px;
            left: 0;
        }
        @media(max-width: $md5+px) {
            left: -100px;
        }
        @media(max-width: $md6+px) {
            left: -250px;
        }
    }
    &Body {
        position: relative;
        z-index: 2;
        .title {

        }
        .subtitle {
            margin: 40px 0 20px 0;
            @media(max-width: $md6+px) {
                margin: 10px 0 20px 0;
            }
        }
        .text {
            max-width: 712px;
            @media(max-width: $md1+px) {
                max-width: 666px;
            }
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 20px;
            margin-top: 60px;
            @media(max-width: 800px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: repeat(4, 1fr);
            }
            @media(max-width: 600px) {
                display: flex;
                flex-direction: column;
            }
            .card {
                background: #FBFBFB;
                border: 1px solid #F8F8F8;
                border-radius: 10px;
                padding: 20px 160px 55px 100px;
                @media(max-width: $md1+px) {
                    padding: 20px 66px 30px 60px;
                }
                @media(max-width: $md3+px) {
                    padding: 20px 40px 30px 40px;
                }
                @media(max-width: 800px) {
                    padding: 20px 66px 30px 60px;
                }
                @media(max-width: 600px) {
                    padding: 20px 20px 40px 20px;
                }
                &Image {
                    mix-blend-mode: darken;
                    width: auto;
                    height: 160px;
                    @media(max-width: $md1+px) {
                        height: 120px;
                    }
                    @media(max-width: $md6+px) {
                        height: 80px;
                    }
                }
                &Title {
                    font-family: $manrope!important;
                    font-weight: 700!important;
                    margin: 40px 0 20px 0;
                    color: #587291;
                    max-width: 430px;
                    @media(max-width: $md6+px) {
                        margin: 30px 0 10px 0;
                    }
                }
            }
        }
        .bottomCard {
            background: #FBFBFB;
            border: 1px solid #F8F8F8;
            border-radius: 10px;
            margin-top: 20px;
            display: flex;
            gap: 45px;
            justify-content: center;
            align-items: center;
            padding: 37px;
            @media(max-width: $md1+px) {
                padding: 30px;
                gap: 30px;
            }
            @media(max-width: 600px) {
                padding: 20px 20px 40px 20px;
                flex-direction: column;
                gap: 20px;
            }
            .bottomCardIcon {
                height: auto;
                width: 54px;
                @media(max-width: $md1+px) {
                    width: 28px;
                }
            }
            .bottomCardText {
                color: rgba(0, 0, 0, 0.5);
                max-width: 1028px;
                @media(max-width: $md1+px) {
                    max-width: 837px;
                }
            }
        }
    }
}