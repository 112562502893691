@import '../../../styles/var';

.accountTypesWrap {
    position: relative;

    .title {
        text-align: center;
        font-weight: 600;
        color: #587291;
    }

    .buttons {
        position: relative;
        width: 700px;
        margin: 75px auto 60px auto;
        display: flex;
        justify-content: space-between;
        gap: 10px;

        @media(max-width: $md1+px) {
            margin: 60px auto 50px auto;
            width: 570px;
        }

        @media(max-width: $md4+px) {
            margin: 55px auto;
        }

        @media(max-width: $md4+px) {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 20px;
        }

        @media(max-width: 600px) {
            grid-template-columns: repeat(2, 1fr);
            gap: 15px;
        }

        @media(max-width: $md6+px) {
            margin: 30px auto 40px;
            gap: 10px;
        }

        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            height: 8px;
            width: 100%;
            background: rgba(222, 222, 222, 0.4);
            border-radius: 2px;

            @media(max-width: $md4+px) {
                content: unset;
            }
        }

        .button {
            cursor: pointer;
            position: relative;
            padding-bottom: 38px;
            text-align: center;
            font-family: $roboto;
            font-weight: 800;
            font-size: 20px;
            line-height: 120%;
            color: #2E2E2E;

            @media(max-width: $md1+px) {
                font-size: 16px;
            }

            @media(max-width: $md4+px) {
                padding-bottom: 0;
                border: 1px solid #2E2E2E;
                color: #2E2E2E;
                border-radius: 5px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                text-align: center;
                justify-content: center;
                display: flex;
                padding: 7px 10px;
            }

            &Active {
                @media(min-width: 991px) {
                    span {
                        opacity: 1 !important;
                    }
                }
            }

            &ActiveMob {
                @media(max-width: $md4+px) {
                    background: #587291;
                    border-color: #587291;
                    color: #fff;

                    span {
                        opacity: 1 !important;
                    }
                }
            }

            span {
                opacity: 0.4;
                transition: all .3s;

                @media(max-width: $md4+px) {
                    opacity: 1 !important;
                }
            }

            &:nth-child(1) {
                text-align: left;
            }

            &:nth-last-child(1) {
                text-align: right;
            }
        }

        .label {
            transition: all .3s;
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100px;
            height: 8px;
            background: #587291;
            border-radius: 2px;

            @media(max-width: 990px) {
                display: none;
            }
        }
    }

    .swiperContainer {
        @media(max-width: $md3+px) {
            width: 100% !important;
            max-width: 100% !important;
            padding: 0 !important;
        }
    }

    .swiperWrap {

        :global(.accountTypesSwiper) {
            height: 100% !important;
            overflow: visible;

            @media(max-width: $md3+px) {
                padding: 0 40px;
            }

            @media(max-width: 600px) {
                padding: 0 18px;
            }
        }

        :global(.accountTypesSwiperSlide) {
            height: auto !important;
        }

        .card {
            position: relative;
            height: 100%;
            border-radius: 15px;
            overflow: hidden;
            padding: 30px 20px;
            width: 100%;
            margin: 0 auto;
            transition: all .3s;

            background: rgba(215, 223, 233, 0.7);
            border: 1px solid #efefef;
            box-shadow: 0px 3px 10px 3px rgba(0, 71, 193, 0.06);
            border-radius: 8px;

            &:hover {
                box-shadow: 0px 20px 20px rgba(0, 0, 0, 0.01), 0px 20px 20px rgba(0, 0, 0, 0.09);
            }

            @media(max-width: $md6+px) {
                padding: 20px 15px;
            }

            &Content {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                position: relative;
                z-index: 10;
                height: 100%;
                gap: 30px;

                @media(max-width: $md4+px) {
                    gap: 40px;
                }

                @media(max-width: $md6+px) {
                    gap: 20px;
                }

                &Text {
                    .cardTitle {
                        text-align: center;
                        font-weight: 800;
                        color: #587291;

                        @media (max-width: $md4+px) {
                            font-size: 18px;
                        }

                        @media (max-width: $md6+px) {
                            font-size: 16px;
                        }
                    }

                    ul {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        margin-top: 30px;
                        padding-left: 20px;
                        font-family: $roboto;
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 19px;
                        color: rgba(0, 0, 0, 0.7);

                        @media(max-width: $md1+px) {
                            font-size: 14px;
                            line-height: 17px;
                            margin-top: 25px;
                        }

                        @media (max-width: $md6+px) {
                            gap: 8px;
                            margin-top: 20px;
                            padding-left: 16px;
                        }

                        li {
                            list-style-position: outside;
                            list-style-type: disc;

                            .liContent {
                                display: flex;
                                align-items: center;
                                gap: 5px;

                                img {
                                    width: 20px;
                                }
                            }
                        }
                    }
                }

                .btn {
                    display: flex;
                    justify-content: center;
                }
            }
        }
    }
}


.textassets {
    margin-top: 10px;
}