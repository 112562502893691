@import '../../../styles/var';

.ourValues {
    position: relative;
    z-index: 10;
    .title {
        text-align: center;
    }
    .cards {
        margin-top: 60px;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 20px;
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(4, 1fr);
        }
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
        .card {
            overflow: hidden;
            position: relative;
            padding: 40px 230px 38px 60px;
            background: #D7DFE9;
            border: 1px solid #F8F8F8;
            border-radius: 10px;
            @media(max-width: $md1+px) {
                padding: 40px 116px 60px 40px;
            }
            @media(max-width: $md2+px) {
                padding: 40px 130px 40px 40px;
            }
            @media(max-width: $md3+px) {
                padding-right: 150px;
            }
            @media(max-width: $md4+px) {
                padding: 40px 160px 60px 40px;
            }
            @media(max-width: $md5+px) {
                padding-right: 130px;
            }
            @media(max-width: $md6+px) {
                padding: 40px 20px 156px 30px;
            }
            &:nth-child(1) {
                .cardImage {
                    right: -155px;
                    @media(max-width: $md1+px) {
                        right: -131px;
                    }
                }
            }
            &:nth-child(2) {
                .cardImage {
                    right: -119px;
                    @media(max-width: $md1+px) {
                        right: -126px;
                    }
                }
            }
            &:nth-child(3) {
                .cardImage {
                    right: -105px;
                    @media(max-width: $md1+px) {
                        right: -123px;
                    }
                }
            }
            &:nth-child(4) {
                .cardImage {
                    right: -130px;
                    @media(max-width: $md1+px) {
                        right: -133px;
                    }
                }
            }
            &Image {
                width: auto;
                height: 100%;
                position: absolute;
                z-index: 1;
                top: 0;
                @media(max-width: $md3+px) {
                    height: 90%;
                    top: 5%;
                }
                @media(max-width: $md4+px) {
                    height: 100%;
                    top: 0;
                }
                @media(max-width: $md6+px) {
                    left: 0!important;
                    right: 0!important;
                    top: unset;
                    margin: 0 auto;
                    height: auto;
                    width: 253px;
                    bottom: -103px;
                }
            }
            &Title {
                position: relative;
                z-index: 10;
                color: #587291;
            }
            &Text {
                position: relative;
                z-index: 10;
                margin-top: 20px;
                @media(max-width: $md6+px) {
                    margin-top: 15px;
                }
            }
        }
    }
}