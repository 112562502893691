@import '../../../styles/var';

.wrap {
    position: relative;
    overflow: hidden;
    background-image: url('../../../assets/img/QuaterlyPage/banner_bg.jpg');
    background-repeat: no-repeat;
    background-position: right bottom;
    background-size: cover;
    padding: 90px 55px 90px;
    margin-top: 80px;

    @media(max-width: $md3+px) {
        padding: 60px 55px 60px;
    }

    @media(max-width: $md4+px) {
        padding: 120px 0 0;
        margin-top: 0;
        background-image: none;
    }

    @media(max-width: $md6+px) {
        padding-top: 130px;
    }

    .content {
        position: relative;
        z-index: 5;
        color: #587291;

        .title {

        }

        .text {
            margin-top: 20px;
            max-width: 500px;

            @media (max-width: $md1+px) {
                max-width: 430px;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 140px;
        bottom: 0;
        // width: 858px;
        height: 100%;

        @media (max-width: $md1+px) {
            right: 100px;
        }

        @media (max-width: $md2+px) {
            right: 50px;
        }

        @media(max-width: $md3+px) {
            right: 0;
        }

        @media(max-width: $md4+px) {
           display: none;
        }
    }
}