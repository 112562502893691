@import '../../../styles/var';

.howToGet {
    position: relative;
    z-index: 10;
    .title {
        text-align: center;
    }
    .container {
        position: relative;
    }
    .content {
        position: relative;
        margin-top: 80px;
        @media(max-width: $md1+px) {
            margin-top: 60px;
        }
        @media(max-width: $md6+px) {
            margin-top: 80px;
        }
        .cards {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
            gap: 40px 24px;
            @media(max-width: $md1+px) {
                gap: 40px 20px;
            }
            @media(max-width: $md4+px) {
                grid-template-columns: repeat(1, 1fr);
                grid-template-rows: auto;
            }
            @media(max-width: $md6+px) {
                display: none;
            }
        }
        .card {
            position: relative;
            background: #D7DFE9;
            border: 1px solid #F8F8F8;
            border-radius: 10px;
            margin-left: 40px;
            display: grid;
            padding: 60px 50px 44px 80px;
            grid-template-columns: 163px 1fr;
            align-items: center;
            gap: 30px;
            &:hover {
                .cardNum {
                    transform: scale(0.85);
                }
            }
            @media(max-width: $md1+px) {
                margin-left: 30px;
                grid-template-columns: 145px 1fr;
                padding: 52px 38px 40px 60px;
                gap: 40px;
            }
            @media(max-width: $md3+px) {
                grid-template-columns: 120px 1fr;
                gap: 30px;
                padding: 40px 30px 40px 40px;
            }
            @media(max-width: $md4+px) {
                gap: 40px;
            }
            @media(max-width: 600px) {
                grid-template-columns: 100 1fr;
                gap: 30px;
            }
            @media(max-width: $md6+px) {
                display: flex;
                flex-direction: column;
                padding: 50px 20px 40px 20px;
                gap: 40px;
                margin-left: 0;
                height: 100%;
            }
            .cardNum {
                transition: all .3s;
                position: absolute;
                width: 80px;
                height: 80px;
                border-radius: 100%;
                left: -40px;
                top: calc(50% - 40px);
                background: #D7DFE9;
                font-family: $manrope;
                font-weight: 400;
                font-size: 24px;
                line-height: 33px;
                color: #587291;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                @media(max-width: $md1+px) {
                    font-size: 18px;
                    line-height: 25px;
                    width: 60px;
                    height: 60px;
                    left: -30px;
                    top: calc(50% - 30px);
                }
                @media(max-width: $md6+px) {
                    top: -30px;
                    left: calc(50% - 30px);
                }
            }
            .cardImage {
                position: relative;
                width: 100%;
                img {
                    height: auto;
                    width: 100%;
                    @media(max-width: $md6+px) {
                        width: 145px;
                        display: flex;
                        margin: 0 auto;
                    }
                }
            }
            .cardContent {
                .cardTitle {
                    font-family: $manrope;
                    font-weight: 600;
                    font-size: 24px;
                    line-height: 140%;
                    color: #587291;
                    @media(max-width: $md1+px) {
                        font-size: 20px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 16px;
                    }
                }
                .cardText {
                    margin-top: 10px;
                }
            }
        }
        .swiperWr {
            display: none;
            @media(max-width: $md6+px) {
                display: block;
            }
        }
        :global(.howToGetSwiper) {
            height: 100%!important;
            overflow: visible;
            padding-bottom: 28px;
        }
        :global(.howToGetSwiperSlide) {
            height: auto!important;
        }
        :global(.swiper-pagination) {
            bottom: 0;
        }
        :global(.swiper-pagination-bullet) {
            opacity: 1;
            background: #D7DFE9;
        }
        :global(.swiper-pagination-bullet-active) {
            background: #587291;
        }
    }
}