@import "../../../styles/var";
.four {
}
.fourBody {
}
.fourRow {
    display: flex;
    justify-content: space-between;
    @media (max-width: $md3+px) {
        flex-wrap: wrap;
    }
}
.fourRowLeft {
    @media (max-width: $md3+px) {
        flex: 0 1 100%;
    }
}
.fourRowLeftTitle {
    font-weight: 500;
    color: rgba(0, 0, 0, 0.7);
    margin-bottom: 40px;
    max-width: 730px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
}
.fourRowLeftText {
    max-width: 683px;
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 30px;
    }
}
.fourRowLeftListTitle {
    font-weight: 500;
    color: #587291;
    margin-bottom: 20px;
}
.fourRowLeftList {
    display: flex;
    @media (max-width: $md5+px) {
        flex-wrap: wrap;
    }
}
.fourRowLeftListLeft {
    flex: 0 1 412px;
}
.fourRowLeftListItem {
    margin-bottom: 12px;
    font-weight: 400;
    &:last-child {
        margin-bottom: 0;
    }
    padding-left: 30px;
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 6px;
        position: absolute;
        width: 18px;
        height: 18px;
        background: url("../../../assets/img/trading/3/2/2.png") 0 0 / contain no-repeat;
        @media (max-width: $md1+px) {
            top: 3px;
        }
        @media (max-width: $md4+px) {
            top: 0;
        }
    }
}
.fourRowLeftListRight {
    flex: 0 1 480px;
    margin-left: 5px;
    @media (max-width: $md5+px) {
        margin-left: 0;
        margin-top: 15px;
    }
}
.fourRowRight {
    flex: 0 1 640px;
    @media (max-width: $md3+px) {
        margin: 50px auto 0 auto;
    }
    transform: scale(0.4);
    @media (max-width: $md3+px) {
        transform: scale(1);
    }
    img {
        width: 100%;
    }
}
