@import '../../../styles/var';

@mixin keyframes($animation-name) {
    @-webkit-keyframes #{$animation-name} {
        @content;
    }
    @-moz-keyframes #{$animation-name} {
        @content;
    }
    @-ms-keyframes #{$animation-name} {
        @content;
    }
    @-o-keyframes #{$animation-name} {
        @content;
    }
    @keyframes #{$animation-name} {
        @content;
    }
}

@mixin animation($str) {
    -webkit-animation: #{$str};
    -moz-animation: #{$str};
    -ms-animation: #{$str};
    -o-animation: #{$str};
    animation: #{$str};
}

@include keyframes(icon_anim_01) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(10px, 30px);
    }
}
@include keyframes(icon_anim_02) {
    0% {
        transform: translate(-5px, 40px);
    }
    100% {
        transform: translate(0, 0);
    }
}
@include keyframes(icon_anim_03) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 25px);
    }
}
@include keyframes(icon_anim_04) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 40px);
    }
}
@include keyframes(icon_anim_05) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, -25px);
    }
}
@include keyframes(icon_anim_06) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(-10px, -10px);
    }
}
@include keyframes(icon_anim_07) {
    0% {
        transform: translate(0, 0px);
    }
    100% {
        transform: translate(0, 20px);
    }
}

.banner {
    position: relative;
    padding-top: 176px;
    @media(max-width: $md1+px) {
        padding-top: 155px;
    }
    @media(max-width: $md4+px) {
        padding-top: 120px;
    }
    @media(max-width: $md6+px) {
        padding-top: 130px;
    }
    .light {
        position: absolute;
        top: 0;
        right: 0;
        height: auto;
        width: 1058px;
        z-index: 1;
        pointer-events: none;
        @media(max-width: $md1+px) {
            width: 800px;
        }
        @media(max-width: $md2+px) {
            width: 700px;
        }
        @media(max-width: $md3+px) {
            width: 90%;
            bottom: -150px;
            top: unset;
        }
        @media(max-width: $md6+px) {
            width: 130%;
        }
    }
    &Body {
        position: relative;
        z-index: 5;
        .subtitle {

        }
        .title {
            max-width: 937px;
            @media(max-width: $md1+px) {
                margin-top: 6px;
            }
            @media(max-width: $md2+px) {
                max-width: 760px;
            }
            @media(max-width: $md4+px) {
                max-width: 660px;
                margin-top: 10px;
            }
            span {
                color: rgba(88, 114, 145, 1);
                font-weight: 600;
            }
        }
        .text, .subtext {
            max-width: 683px;
            @media(max-width: $md1+px) {
                max-width: 666px;
            }
        }
        .text {
            margin: 40px 0 20px 0;
        }
        .subtext {

        }
        .btn {
            margin-top: 40px;
        }
        img {
            height: auto;
            position: absolute;
        }
        .image {
            z-index: 3;
            width: 726px;
            right: 50px;
            bottom: -80px;
            @media(max-width: $md1+px) {
                width: 460px;
                right: 80px;
                bottom: 0;
            }
            @media(max-width: $md2+px) {
                right: 50px;
                width: 442px;
            }
            @media(max-width: $md3+px) {
                position: relative;
                display: flex;
                margin: 80px auto 0 auto;
                right: unset;
                bottom: unset;
            }
            @media(max-width: 600px) {
                width: 270px;
                margin-left: 0;
            }
        }
        .icon {
            position: absolute;
            z-index: 4;
            @media(max-width: $md1+px) {
                transform: scale(0.6);
            }
            @media(max-width: $md3+px) {
                left: 0;
                right: 0;
                margin: 0 auto;
            }
            @media(max-width: 600px) {
                transform: scale(0.4);
                right: unset!important;
            }
            img {
                height: auto;
                width: 100%;
            }
            &_01 {
                width: 112px;
                bottom: 440px;
                right: 590px;
                img {
                    @include animation("icon_anim_01 linear 2s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 320px;
                    right: 400px;
                }
                @media(max-width: $md3+px) {
                    right: 290px;
                    bottom: 310px;
                }
                @media(max-width: 600px) {
                    bottom: 195px;
                    left: -10px;
                }
            }
            &_02 {
                width: 112px;
                bottom: 570px;
                right: 490px;
                img {
                    @include animation("icon_anim_02 linear 2.5s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 400px;
                    right: 340px;
                }
                @media(max-width: $md3+px) {
                    bottom: 400px;
                    right: 200px;
                }
                @media(max-width: 600px) {
                    bottom: 255px;
                    left: 30px;
                }
            }
            &_03 {
                width: 128px;
                bottom: 570px;
                right: 320px;
                img {
                    @include animation("icon_anim_03 linear 3s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 400px;
                    right: 230px;
                }
                @media(max-width: $md3+px) {
                    right: 0px;
                    bottom: 390px;
                }
                @media(max-width: 600px) {
                    bottom: 250px;
                    left: 85px;
                }
            }
            &_04 {
                width: 76px;
                bottom: 700px;
                right: 250px;
                img {
                    @include animation("icon_anim_04 linear 2.2s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 480px;
                    right: 200px;
                }
                @media(max-width: $md3+px) {
                    bottom: 450px;
                    right: -150px;
                }
                @media(max-width: 600px) {
                    bottom: 300px;
                    left: 150px;
                } 
            }
            &_05 {
                width: 119px;
                bottom: 600px;
                right: 60px;
                img {
                    @include animation("icon_anim_05 linear 3.5s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 430px;
                    right: 70px;
                }
                @media(max-width: $md3+px) {
                    right: -300px;
                    bottom: 410px;
                }
                @media(max-width: 600px) {
                    bottom: 260px;
                    left: 180px;
                }
            }
            &_06 {
                width: 111px;
                bottom: 520px;
                right: -90px;
                img {
                    @include animation("icon_anim_06 linear 2.8s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 380px;
                    right: -30px;
                }
                @media(max-width: $md3+px) {
                    bottom: 370px;
                    right: -450px;
                }
                @media(max-width: 600px) {
                    bottom: 240px;
                    left: 260px;
                }
            }
            &_07 {
                width: 137px;
                bottom: 360px;
                right: -60px;
                img {
                    @include animation("icon_anim_07 linear 2.2s alternate infinite");
                }
                @media(max-width: $md1+px) {
                    bottom: 280px;
                    right: -10px;
                }
                @media(max-width: $md3+px) {
                    right: -450px;
                }
                @media(max-width: 600px) {
                    left: 220px;
                    bottom: 170px;
                }
            }
        }
    }
}