@import '../../../styles/var';

.welcomeBonus {
    position: relative;
    z-index: 10;
    display: grid;
    grid-template-columns: 1fr 683px;
    gap: 60px;
    align-items: center;
    padding-top: 100px;
    @media(max-width: $md1+px) {
        grid-template-columns: 1fr 560px;
        padding-top: 50px;
    }
    @media(max-width: $md2+px) {
        padding-top: 0;
    }
    @media(max-width: $md3+px) {
        display: flex;
        flex-direction: column-reverse;
        gap: 40px;
        align-items: unset;
    }
    .image {
        @media(max-width: $md3+px) {
            display: flex;
            justify-content: center;
        }
        img {
            height: auto;
            width: 757px;
            @media(max-width: $md1+px) {
                width: 600px;
            }
            @media(max-width: $md2+px) {
                width: 550px;
            }
            @media(max-width: $md3+px) {
                width: 600px;
            }
            @media(max-width: $md4+px) {
                width: 500px;
            }
            @media(max-width: $md5+px) {
                width: 420px;
            }
            @media(max-width: $md6+px) {
                width: 335px;
            }
        }
    }
    .content {
        .title {

        }
        .text {
            margin: 40px 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            @media(max-width: $md4+px) {
                margin: 40px 0 30px 0;
            }
            @media(max-width: $md6+px) {
                margin: 20px 0;
            }
        }
        ul {
            display: flex;
            flex-direction: column;
            gap: 17px;
            font-weight: 400;
            @media(max-width: $md6+px) {
                gap: 9px;
            }
            li {
                font-weight: 400;
                color: #587291;
                padding-left: calc(18px + 15px);
                position: relative;
                b {
                    font-weight: 600;
                }
                @media(max-width: $md6+px) {
                    padding-left: 24px;
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 6px;
                    width: 18px;
                    height: 18px;
                    background-image: url('../../../assets/img/HomePage/QuickInfo/check.svg');
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    @media(max-width: $md1+px) {
                        top: 3px;
                    }
                    @media(max-width: $md4+px) {
                        top: 1px;
                    }
                    @media(max-width: $md6+px) {
                        width: 12px;
                        height: 12px;
                        top: 3px;
                    }
                }
            }
        }
        .btn {
            margin-top: 40px;
        }
    }
}