@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    transition: all .3s;

    &.hideBg {
        background-color: rgba(255, 255, 255, 1);
    }

    &.lightBg {
        color: red !important;

        .headerRowLinks {
            a {
                color: #FFF;
            }

            .linkActive {
                color: #EF6A70;
            }
        }

        :global(.Dropdown-control) {
            color: #FFF !important;
        }
    }

    @media(max-width: $md3+px) {
        height: 80px;
    }

    @media(max-width: $md6+px) {
        height: 71px;
    }

    &::before {
        content: '';
        background: #587291;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all .3s;
        z-index: -1;
    }

    &Active {
        height: 100%;

        &::before {
            opacity: 1;
            height: 100%;
        }

        .langs {
            display: none;
        }

        path {
            fill: white;
        }

        .headerRowLogo {
            filter: brightness(0) invert(1);
        }
    }

    .mobileMenu {
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;

        @media(min-width: 1221px) {
            display: none;
        }

        .aLink {
            display: block;
            text-align: center;
            color: #FFF;
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;
        }

        .btn {
            justify-content: center;
            display: flex;
            margin-top: 40px;
        }

        &Active {
            display: flex;
            transition: transform .5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    .headerRow {
        display: grid;
        grid-template-columns: 70px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 10px 0;

        @media(max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }

        @media(max-width: $md6+px) {
            padding: 10px 0;
        }

        &Logo {
            @media(max-width: $md3+px) {
                width: 70px;
            }

            @media(max-width: $md6+px) {
                width: 60px;
            }

            img {
                width: 100%;
            }
        }

        &Links {
            display: flex;
            gap: 40px;
            font-family: $roboto;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;

            @media (max-width: $md1+px) {
                font-size: 16px;
                gap: 30px;
            }

            @media (max-width: $md3+px) {
                display: none;
            }

            .linkItem {
                position: relative;
                color: #313131;
                text-decoration: none;
                display: flex;
                align-items: center;
                gap: 5px;

                &:hover {
                    color: rgba($color: #313131, $alpha: 0.8);
                }
            }

            .listWrap {
                position: relative;

                &:hover {
                    .subRoutes {
                        opacity: 1;
                        pointer-events: visible;
                    }

                    svg {
                        transform: rotate(180deg);
                    }
                }

                svg {
                    transition: all .3s;
                }

                .subRoutes {
                    -webkit-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    -moz-box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
                    box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);

                    position: absolute;
                    background: #fff;
                    white-space: nowrap;
                    left: -10px;
                    top: 18px;
                    display: flex;
                    flex-direction: column;
                    gap: 0px;
                    padding-top: 0px;

                    opacity: 0;
                    pointer-events: none;

                    transition: all .3s;

                    a {
                        padding: 6px 10px;
                    }
                }
            }
        }

        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;

            .login {
                padding: 7px 18px;
                background: #587291;
                border: 1px solid #587291;
                border-radius: 30px;
                font-family: $roboto;
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 23px;
                color: #fff;
                cursor: pointer;
                transition: all .3s;

                @media(max-width: $md3+px) {
                    display: none;
                }

                &:hover {
                    color: #000;
                    background: transparent;
                }
            }

            .burger {
                display: flex;
                width: 32px;
                height: 14px;
                position: relative;

                @media(min-width: 1221px) {
                    display: none;
                }

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #587291;
                    border-radius: 10px;
                    transition: all .3s;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background: #587291;
                    border-radius: 10px;
                    transition: all .3s;
                }

                &Active {
                    &::before {
                        top: 6px;
                        transform: rotate(45deg);
                        background: #FFF;
                    }

                    &::after {
                        bottom: 6px;
                        transform: rotate(-45deg);
                        background: #FFF;
                    }
                }
            }
        }
    }
}

:global(.Dropdown-menu) {
    max-height: unset!important;
}