@import "../../../styles/_var.scss";

.most {
    position: relative;
    z-index: 40;
}

.mostBody {}

.mostTitle {
    max-width: 1146px;
    margin: 0 auto 20px auto;
}

.mostSpoiler {
    max-width: 1146px;
    margin: 0 auto;
    position: relative;
    display: flex;
    flex-direction: column;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    &:after {
        content: "";
        display: "inline-block";
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 1346px;
        height: 1px;
        display: block;
        background: #d4dae3;
        left: 50%;
        margin-right: -50%;
        transform: translate(-50%, -50%);

        @media (max-width: $md4+px) {
            width: 1346px;
        }
    }
}

.mostSpoilerTitle {
    padding: 30px 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    font-weight: 600 !important;
    font-family: $manrope;
    color: #587291 !important;
    padding-right: 50px;

    @media (max-width: $md5+px) {
        padding-right: 100px;
    }

    @media (max-width: $md6+px) {
        padding-right: 40px;
    }

    @media (max-width: $md6+px) {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &:after {
        content: "";
        display: "inline-block";
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 20px;
        height: 3px;
        background-color: #587291;

        @media (max-width: $md6+px) {
            width: 10px;
            height: 2px;
        }
    }

    &:before {
        content: "";
        display: "inline-block";
        right: 8px;
        top: 0;
        bottom: 0;
        margin: auto;
        position: absolute;
        width: 3px;
        height: 20px;
        background-color: #587291;

        @media (max-width: $md6+px) {
            width: 2px;
            height: 10px;
            right: 4px;
        }
    }

    &.active {
        &:before {
            transform: rotate(90deg);
        }
    }
}

.mostSpoilerContent {
    font-weight: 300 !important;
    color: rgba(0, 0, 0, 0.7);
    max-width: 800px;
    position: relative;
    display: none;

    &:hover {
        &:after {
            transform: scale(1.5);
        }
    }

    &.active {
        display: block;
        padding-bottom: 30px;
        
        @media (max-width: $md6+px) {
            padding-bottom: 20px;
        }
    }
}

.mostBtn {
    max-width: 1146px;
    margin: 30px auto 0 auto;
}