@import '../../../styles/var';

.container {
    position: relative;

    .bg {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 0;
        width: 100%;
        height: auto;

        @media(max-width: $md4+px) {
            left: unset;
            right: 0;
            width: 1400px;
        }

        @media(max-width: $md5+px) {
            transform: rotate(90deg);
            width: 1000px;
            right: unset;
            left: -360px;
            bottom: 0px;
        }
    }
}

.footer {
    position: relative;
    z-index: 2;
    padding-bottom: 50px;
    display: grid;
    grid-template-columns: 55px 180px 1fr;
    gap: 140px;
    color: rgba(0, 0, 0, 0.7);
    font-family: $roboto;
    font-weight: 300;
    font-size: 12px;
    line-height: 140%;
    color: rgba(0, 0, 0, 0.7);

    @media(max-width: $md1+px) {
        gap: 100px;
    }

    @media(max-width: $md2+px) {
        gap: 60px;
    }

    @media(max-width: $md4+px) {
        font-size: 10px;
        gap: 40px;
        grid-template-columns: repeat(3, 1fr);

        .logo {}

        .links {
            grid-column-start: 2;
            grid-column-end: 4;
        }

        .disclaimer {
            grid-column-start: 1;
            grid-column-end: 4;
        }
    }

    @media(max-width: $md5+px) {
        grid-template-columns: repeat(1, 1fr);

        .links {
            grid-column-start: unset;
            grid-column-end: unset;
        }

        .disclaimer {
            grid-column-start: unset;
            grid-column-end: unset;
        }
    }

    .logo {}

    .links {
        display: flex;
        flex-direction: column;
        gap: 15px;

        @media(max-width: $md4+px) {
            gap: 18px;
            grid-template-columns: repeat(2, 1fr);
            display: grid;
            gap: 18px 40px;
        }

        @media(max-width: $md5+px) {
            gap: 18px;
        }

        &Item {
            display: block;
        }

        a {
            display: inline-flex;
            color: rgba(0, 0, 0, 0.7);
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;

            @media(max-width: $md4+px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }

    .disclaimer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        padding-left: 100px;
        gap: 20px;

        @media(max-width: $md1+px) {
            padding-left: 0;
        }

        &Title {
            font-weight: 400;
        }

        &Text {
            display: flex;
            flex-direction: column;
            gap: 10px;
            margin-top: 6px;
        }

        .rights {
            font-family: $roboto;
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: rgba(0, 0, 0, 0.7);

            @media(max-width: $md1+px) {
                font-size: 14px;
                line-height: 16px;
            }
        }
    }
}

a {
    &:hover {
        color: #000!important;
    }
}