@import '../../../styles/var';

.wrap {
    position: relative;
    display: grid;
    grid-template-columns: repeat(2, minmax(300px, 663px));
    justify-content: space-between;
    gap: 50px;

    @media (max-width: $md4+px) {
        display: flex;
        flex-direction: column;
        gap: 120px;
    }

    .block {

        .title {
            margin-bottom: 20px;
        }

        .list {
            display: flex;
            flex-direction: column;
            gap: 15px;

            .li {
                display: grid;
                grid-template-columns: 18px 1fr;
                gap: 15px;

                @media (max-width: $md6+px) {
                    grid-template-columns: 16px 1fr;
                }

                .mark {
                    width: 100%;
                    padding-top: 4px;

                    @media (max-width: $md1+px) {
                        padding-top: 1px;
                    }

                    @media (max-width: $md4+px) {
                        padding-top: 0;
                    }
                }

                .content {

                    .subtitle {
                        color: #587291;
                        font-weight: 700;
                        line-height: 140%;
                    }

                    .text {
                        margin-top: 15px;
                    }
                }
            }
        }

        .text {

        }
    }
}