@import '../../../styles/var';

.wrap {
    background: #D1DEF8;
    padding: 50px 0;

    .body {

        .title {
            text-align: center;
        }

        .cards {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            margin-top: 40px;
            gap: 40px;

            @media (max-width: $md5+px) {
                display: flex;
                flex-direction: column;
            }

            .card {
                text-align: center;

                .num {
                    color: #494A4B;
                    font-family: $manrope;
                    font-size: 80px;
                    font-weight: 800;
                    line-height: 120%;

                    @media (max-width: $md1+px) {
                        font-size: 65px;
                    }

                    @media (max-width: $md4+px) {
                        font-size: 50px;
                    }

                    @media (max-width: $md6+px) {
                        font-size: 40px;
                    }
                }

                .subtitle {
                    margin: 15px 0 10px;
                    font-weight: 500;

                    @media (max-width: $md6+px) {
                        margin-top: 10px;
                    }
                }

                .desc {
                    color: rgba(0, 0, 0, 0.70);
                    font-family: $manrope;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 150%;
                    max-width: 250px;
                    margin: 0 auto;

                    @media (max-width: $md6+px) {
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

.text {
    margin: 20px 0 0 auto;
    width: 100%;
    max-width: 517px;
    color: rgba(0, 0, 0, 0.70);
    font-family: $roboto;
    font-size: 12px;
    font-weight: 300;
    line-height: 150%;

    @media (max-width: $md5+px) {
        margin-left: 0;
    }
}