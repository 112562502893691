@import '../../../styles/var';

.meetOur {
    position: relative;

    .title {
        text-align: center;
    }

    .text {
        text-align: center;
        margin: 20px 0 60px 0;

        @media(max-width: $md4+px) {
            margin: 20px 0 40px 0;
        }

        @media(max-width: $md6+px) {
            margin: 10px 0 40px 0;
        }
    }

    .container {
        @media(max-width: $md3+px) {
            max-width: 100% !important;
            width: 100% !important;
            padding: 0 !important
        }

        .contentWr {
            @media(max-width: $md3+px) {
                padding: 0 40px;
                overflow: scroll;
                overflow-y: hidden;
            }

            @media(max-width: 600px) {
                padding: 0 20px;
            }

            .content {
                display: grid;
                grid-template-columns: repeat(5, 1fr);

                @media(max-width: $md3+px) {
                    width: 1200px;
                }

                @media(max-width: $md4+px) {
                    width: 1100px;
                }

                @media(max-width: $md6+px) {
                    width: 950px;
                }

                .col {
                    position: relative;

                    @media(min-width: $md3+px) {
                        &:hover {
                            &::before {
                                height: 100%;
                                width: calc(100% - 10px);
                            }

                            .rowTop {
                                color: #FFFFFF !important;
                            }

                            .rowCenter {
                                color: rgba(255, 255, 255, 0.7);
                            }

                            .btn {
                                border-color: #FFF !important;
                            }

                            .btnText {
                                color: #FFF !important;
                            }

                            .btnArrow {
                                margin-left: 15px !important;

                                path {
                                    stroke: #FFF;
                                }
                            }
                        }
                    }

                    &::before {
                        content: '';
                        z-index: 2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 4px;
                        height: 94px;
                        background: #587291;
                        border-radius: 10px;
                        transition: all .3s;

                        @media(max-width: $md1+px) {
                            height: 80px;
                        }
                    }

                    .row {
                        text-align: center;
                        color: rgba(0, 0, 0, 0.7);

                        &Top {
                            transition: all .3s;
                            position: relative;
                            z-index: 3;
                            height: 94px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            text-align: center;
                            font-family: $manrope;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 24px;
                            line-height: 140%;
                            color: #587291;

                            @media(max-width: $md1+px) {
                                height: 80px;
                                font-size: 20px;
                            }

                            @media(max-width: $md4+px) {
                                font-size: 18px;
                            }

                            @media(max-width: $md6+px) {
                                font-size: 16px;
                            }

                            @media(max-width: $md1+px) {
                                height: 80px;
                            }
                        }

                        &Center {
                            transition: all .3s;
                            padding: 20px 0;
                            font-weight: 400;

                            @media(max-width: $md6+px) {
                                padding: 15px 0;
                            }

                            &Par {
                                position: relative;
                                z-index: 3;
                            }

                            &Border {
                                border-bottom: 1px solid #FFFFFF;
                            }
                        }

                        &Bottom {
                            position: relative;
                            z-index: 5;
                            transition: all .3s;
                            padding: 40px 0;

                            @media(max-width: $md1+px) {
                                padding: 20px 0 40px 0;
                            }

                            @media(max-width: $md4+px) {
                                padding: 10px 0 40px 0;
                            }

                            @media(max-width: $md6+px) {
                                padding: 5px 0 40px 0;
                            }

                            .btn {
                                border: 1px solid #587291;
                                border-radius: 30px;
                                display: inline-flex;
                                position: relative;
                                z-index: 10;
                                cursor: pointer;
                                align-items: center;
                                justify-content: center;

                                height: 51px;
                                padding: 0 20px;

                                @media(max-width: $md1+px) {
                                    height: 47px;
                                    padding: 0 18px;
                                }

                                @media(max-width: $md6+px) {
                                    height: 41px;
                                }

                                .btnText {
                                    font-weight: 400;
                                    color: #587291;
                                    white-space: nowrap;

                                    span {
                                        float: right;
                                    }
                                }

                                .btnArrow {
                                    width: 10px;
                                    height: auto;
                                    transition: all .3s;
                                    margin-left: 0;

                                    @media(max-width: $md1+px) {
                                        width: 9px;
                                    }

                                    @media(max-width: $md3+px) {
                                        margin-left: 10px;
                                    }
                                }
                            }
                        }
                    }

                    &:nth-child(1) {
                        &::before {
                            content: none !important;
                        }

                        .rowCenter {
                            font-weight: 300;
                            color: rgba(0, 0, 0, 0.7) !important;
                        }

                        .rowBottom .btn {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}