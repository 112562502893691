@import "../../../styles/var";
.second {
    position: relative;
    z-index: 2;
    margin: 100px 0 0 0;
}

.second {
}
.secondBody {
}
.secondTitle {
    margin-bottom: 40px;
    @media (max-width: 650px) {
        max-width: 400px;
        margin: 0 auto 40px auto;
    }
    @media (max-width: $md6+px) {
        margin: 0 auto 20px auto;
    }
}
.secondSlider {
    :global(.swiper) {
        overflow: visible;
    }
    margin-bottom: 40px;
    @media (max-width: $md6+px) {
        margin-bottom: 20px;
    }
    @media (max-width: $md1+px) {
        width: 1500px;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
}
.slide {
    width: 400px;
    height: 383px;
    @media (max-width: $md1+px) {
        width: 360px;
    }
    @media (max-width: 800px) {
        width: 100%;
    }
    @media (max-width: 650px) {
        width: 400px;
        margin: 0 auto;
    }
    @media (max-width: 450px) {
        width: 100%;
    }
}
.slideItem {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 40px 30px 5px 30px;
    border-radius: 8px;
    overflow: hidden;
    @media (max-width: $md6+px) {
        padding: 30px 30px 5px 30px;
    }
    cursor: pointer;
    &.opacity {
        opacity: 0 !important;
        pointer-events: none !important;
    }
    &:hover {
        .slideItemTitle {
            color: #587291;
        }
        .slideItemText {
            opacity: 1;
        }
        &::after {
            opacity: 1;
        }
    }
    @media (max-width: $md3+px) {
        .slideItemTitle {
            color: #587291;
        }
        .slideItemText {
            opacity: 1;
        }
        &::after {
            opacity: 1 !important;
        }
    }
    position: relative;
    &:after {
        content: "";
        display: inline-block;
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        border: 1px solid #efefef;
        box-shadow: 0px 3px 10px 3px rgba(0, 71, 193, 0.06);
        border-radius: 8px;
        transition: all 0.3s linear;
        z-index: 1;
        opacity: 0;
    }
}
.slideItemTitle {
    position: relative;
    z-index: 3;
    font-weight: 700;
    color: #ffffff;
    margin-bottom: 25px;
    transition: all 0.3s linear;
}
.slideItemBg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    img {
        object-fit: cover;
    }
}
.slideItemText {
    position: relative;
    z-index: 2;
    transition: all 0.3s linear;
    opacity: 0;
}
.secondWrappperArrows {
    display: flex;
    justify-content: flex-end;
}
.secondArrows {
    display: flex;
    justify-content: space-between;
    width: 90px;
    @media (max-width: 650px) {
        width: 400px;
        max-width: 100%;
        margin: 0 auto 0px auto;
    }
}
.secondArrowsLeft {
    cursor: pointer;
    transform: rotate(180deg);
    opacity: 0.5;
    transition: all 0.3s linear;
    width: 40px;
    height: 40px;
    @media (max-width: $md6+px) {
        height: 30px;
        width: 30px;
    }
    &:hover {
        opacity: 1;
    }
}
.secondArrowsRight {
    cursor: pointer;
    opacity: 0.5;
    transition: all 0.3s linear;
    width: 40px;
    height: 40px;
    @media (max-width: $md6+px) {
        height: 30px;
        width: 30px;
    }
    &:hover {
        opacity: 1;
    }
}
