@import '../../../styles/var';

.information {
    position: relative;
    margin-top: 180px;

    @media(max-width: $md1+px) {
        margin-top: 150px;
    }

    @media(max-width: $md4+px) {
        margin-top: 120px;
    }

    .content {
        position: relative;
        z-index: 10;

        .title {}

        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-top: 30px;
            max-width: 700px;

            @media(max-width: $md1+px) {
                max-width: 600px;
            }

            @media(max-width: $md3+px) {
                max-width: 100%;
            }
        }
    }

    .image {
        position: absolute;
        z-index: 2;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 500px;

        @media(max-width: $md1+px) {
            width: 550px;
        }

        @media(max-width: $md2+px) {
            width: 450px;
        }

        @media(max-width: $md3+px) {
            position: relative;
            right: unset;
            top: unset;
            transform: none;
            width: 100%;
            max-width: 500px;
            display: flex;
            margin: 30px auto 0 auto;
        }
    }
}